<template>
	<div v-if="loadingGame || loadingLocks" style="text-align: center">
		<i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
	</div>
	<div v-else>
		<div v-if="letters.length > 0">
			<LetterTile class="tile" v-for="(letter, idx) in letters" :key="idx" :letter="letter" :letterValue="points[letter]" />
		</div>
		<div v-else style="text-align: center">
			No game record found
		</div>
		<h2 v-if="best.length > 0">Best Words</h2>
		<div class="best-word" v-for="(w, idx) in best" :key="idx">
			<span>{{w.toUpperCase()}}</span>
			<span>{{score(w)}}</span>
		</div>
		<h2 v-if="locks.length > 0">Leaderboard</h2>
		<div v-for="(lock, idx) in locks" :key="idx" class="player">
			<div>
				<Avatar :label="idx + 1" size="large" shape="circle" />
				<div class="name">{{ lock.playerLabel }}</div>
			</div>
			<div class="word">{{ lock.letters.toUpperCase() }} ({{lock.score}})</div>
		</div>
	</div>
</template>
<script>
import axios from 'axios';
import LetterTile from './LetterTile';
import Avatar from 'primevue/avatar';
import { getScore, DEFAULT_LETTER_SCORES } from '../utils/letters';

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;

export default {
  name: 'YesterdayView',
	props: ['gameId'],
	components: {
		LetterTile,
		Avatar,
	},
	data() {
		return {
			gameData: {},
			letters: "",
			points: {},
			best: [],
			locks: [],
			loadingGame: true,
			loadingLocks: true,
		};
	},
	methods: {
		score(w) {
			return getScore(w.toLowerCase(), this.points)
		},
	},
	mounted() {
		axios.get(`${BACKEND_URL}/game/${this.gameId}`, {
			params: {
				date: 'yesterday',
			},
		}).then(resp => {
			this.letters = resp.data.letters;
			this.points = resp.data.points || DEFAULT_LETTER_SCORES;
			this.best = resp.data.best;
			this.loadingGame = false;
		}).finally(() => { this.loadingGame = false })
		axios.get(`${BACKEND_URL}/locks/${this.gameId}`, {
			params: {
				date: 'yesterday',
			},
		}).then(resp => {
			this.locks = resp.data
			this.loadingLocks = false;
		})
	},
}
</script>
<style lang="scss">
.tile {
	margin: 0 2px;
	display: inline-block;
}
h2 {
	margin-top: 30px;
	font-size: 1.25rem;
}
.best-word {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 4px auto;
	max-width: 200px;
}
.player {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 4px;
	> div {
		display: flex;
		align-items: center;
	}
	.name {
		margin-left: 8px;
		font-weight: bold;
	}
	.score {
		margin-left: 8px;
	}
	.word {
	}
}
</style>
