const _ = require('lodash');

export const DEFAULT_LETTER_SCORES = {
	'a': 1,
	'b': 3,
	'c': 3,
	'd': 2,
	'e': 1,
	'f': 4,
	'g': 2,
	'h': 4,
	'i': 1,
	'j': 8,
	'k': 5,
	'l': 1,
	'm': 3,
	'n': 1,
	'o': 1,
	'p': 3,
	'q': 10,
	'r': 1,
	's': 1,
	't': 1,
	'u': 1,
	'v': 4,
	'w': 4,
	'x': 8,
	'y': 4,
	'z': 10,
}

export const NUM_LETTERS = 7;

export function getScore(word, points) {
	if (!points) points = DEFAULT_LETTER_SCORES;
	const unique = (new Set(word)).size;
	const bonusPoints = unique == NUM_LETTERS ? 2 : 0; // two bonus points for all letters
	return unique + bonusPoints + _.reduce(word, (sum, letter) => sum + points[letter], 0)
}
