<template>
	<div class="container">
		<h2>Create a New Game</h2>
		<InputText v-model="newGameName" placeholder="Name Your Game (e.g. Dodge Family)" class="new-game-input" />
		<Button label="Create Game" :disabled="newGameName.length == 0" @click="newGame" class="new-game-button" />
		<h2>Existing Games</h2>
		<div class="games">
			<div class="game" v-for="game in allGames" :key="game[0]"><router-link :to="'/game/' + game[0]">{{game[1]}}</router-link></div>
		</div>
	</div>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { randomId, getGames, setGameLabel } from '../utils/storage';

export default {
  name: 'GameList',
  components: {
		Button,
		InputText,
  },
	data() {
		return {
			newGameName: '',
		}
	},
	computed: {
		allGames() {
			return getGames();
		},
	},
	methods: {
		newGame() {
			const gameId = randomId();
			setGameLabel(gameId, this.newGameName);
			this.$router.push(`/game/${gameId}`);
		},
	},
}
</script>

<style lang="scss">
h2 {
	display: block;
	text-align: center;
}
.container {
	width: 90%;
	margin: 0 auto;
}
.new-game-input {
	margin: 0 auto;
	width: 100%;
}
.new-game-button {
	margin: 16px auto;
	width: 100%;
}
.games {
	.game {
		margin-bottom: 8px;
		font-size: 1.5rem;
		a {
			text-decoration: none;
			color: var(--primary-color);
		}
	}
}
</style>
