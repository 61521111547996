<template>
	<Header />
	<router-view></router-view>
</template>

<script>
import Header from './components/Header';

export default {
  name: 'App',
  components: {
		Header,
  }
}
</script>

<style>
html, body {
	margin: 0;
	padding: 0;
	height: 100%;
	font-family: "Lato", sans-serif;
}
#app {
	max-width: 550px;
	margin: 0 auto;
}
</style>
