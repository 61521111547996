import '@fontsource/lato'
import 'primevue/resources/themes/lara-light-indigo/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import { createApp } from 'vue'
import * as VueRouter from 'vue-router'
import App from './App.vue'
import PrimeVue from 'primevue/config';

import GameView from './pages/GameView'
import GameList from './pages/GameList'

const routes = [
	{ path: '/', component: GameList },
	{ path: '/game/:gameId', component: GameView },
];
const router = VueRouter.createRouter({
	history: VueRouter.createWebHistory(),
	routes,
})

const app = createApp(App)
app.use(PrimeVue)
app.use(router)

import ConfirmationService from 'primevue/confirmationservice';
app.use(ConfirmationService)

import ToastService from 'primevue/toastservice';
app.use(ToastService)

app.mount('#app')
