import _ from 'lodash';

export function randomId(length=8) {
	return Math.random().toString(36).substr(2).replace(/[^a-z0-9]+/g, '').substr(0, length);
}

export function getGames() {
	const gg = _.reduce(Object.keys(localStorage), (games, key) => {
		if (key.startsWith('g--')) {
			games.push([key.substr(3), localStorage.getItem(key)]);
		}
		return games;
	}, []);
	return gg;
}

export function getUserId() {
	let id = localStorage.getItem('userid');
	if (!id) {
		id = randomId()
		localStorage.setItem('userid', id)
	}
	return id
}

export function getUserName() {
	return localStorage.getItem('username');
}

export function setUserName(name) {
	return localStorage.setItem('username', name);
}

export function getGameLabel(gameId) {
	return localStorage.getItem('g--' + gameId);
}

export function setGameLabel(gameId, gameLabel) {
	localStorage.setItem('g--' + gameId, gameLabel);
}

export function deleteGame(gameId) {
	localStorage.removeItem('g--' + gameId);
}
